import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">

            <header className="App-header">
                <div className="ziba-logo">
                    <img src="/ziba_white.png" className="App-logo-header" alt="logo"/>
                </div>
                <div className="facebook-logo">
                    <a className="App-link"
                       href="https://www.instagram.com/zibahub/"
                       target="_blank"
                       rel="noopener noreferrer">
                        <img src="/instagram.jpg" className="iconSize" alt="instagram"/>
                    </a>

                    <a className="App-link"
                       href="https://twitter.com/ZibaHubTweets"
                       target="_blank"
                       rel="noopener noreferrer">
                        <img src="/twitter.jpg" className="iconSize" alt="twitter"/>
                    </a>

                    <a className="App-link"
                       href="https://www.facebook.com/zibahub/"
                       target="_blank"
                       rel="noopener noreferrer">
                        <img src="/facebook-white-background.png" className="iconSize" alt="facebook"/>
                    </a>
                </div>

            </header>

            <div className="ziba-body-content">
                <div>
                <p style={{
                    color: "#dcadbe",
                    textAlign: "left",
                    letterSpacing: "5px"
                }}><strong>ZIBA IS</strong></p>
                <p style={{
                    fontFamily: "Montserrat",
                    textAlign: "left",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: "28px",
                    letterSpacing: ".01em",
                    lineHeight: "1.2em",
                    textTransform: "none"
                }}>The career platform for beauty and wellness professionals</p>

                <section>
                    <p style={{
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: "400",
                        fontStyle: "normal",
                        fontSize: "17px",
                        letterSpacing: ".02em",
                        lineHeight: "1.8em",
                        textTransform: "none",
                        color: "#121212"
                    }}>Ziba (ZEE-ba) is on a mission to level the playing field in beauty and wellness
                        by providing the same career enablement tools available to other professions in
                        a format that complements the unique needs of this highly creative, bold, and
                        diverse community. </p>
                </section>

                <div className="App-content">
                    <a className="App-link apple"
                       href="https://itunes.apple.com/us/app/zibahub/id1458694046?mt=8"
                       target="_blank"
                       rel="noopener noreferrer">
                    </a>

                    <a className="App-link google"
                       href="https://play.google.com/store/apps/details?id=com.zibahub.app"
                       target="_blank"
                       rel="noopener noreferrer">
                    </a>
                </div>
                </div>
                <div className="ziba-phone-image">
                    <img src='phoneWithAppImage.jpg' alt="Ziba App"
                         style={{ height: "500px"}}/>
                </div>
            </div>
        </div>
    );
}

export default App;
